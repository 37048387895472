import React from "react";
import ProductDetail from "../../../components/ProductDetail";
import ProductForm from "../../../components/ProductForm";
import ShiftForm from "../../../components/ShiftForm";
import Modal from "../../../organism/Modal";
import {
  ProductWrapper,
  ReportsWrapper,
} from "../../../styles/CommonReports.styled";
import { useState, useEffect } from "react";
import { useFirestore, GetFirestoreData } from "../../../Hooks/firebaseFuncs";
import DamagedProductForm from "../../../components/DamagedProductForm";
import { useNavigate } from "react-router-dom";
// import { set } from "firebase/database";

export default function AddProduct({
  setshowProductModal,
  showProductModal,
  setshowShiftModal,
  showShiftModal,
  damagedProductModal,
  setdamagedProductModal,
}) {
  let [TableData, setTableData] = useState([]);
  let [formData, setFormData] = useState({});
  let [shiftData, setShiftData] = useState({});
  let [damagedProductData, setDamagedProductData] = useState({});

  GetFirestoreData("products", 65).then((data) => {
    setTableData(data);
  });

  const { addDocument } = useFirestore("products");
  const { updateDocument } = useFirestore("latest_product");

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      addDocument(formData);
      updateDocument(`machine_${formData.machine_no}`, formData);
      setTimeout(() => {
        navigate("/manage-products");
      }, 1500);
    }
  }, [formData]);

  const { updateDocument: updateShift } = useFirestore("shiftTime");

  useEffect(() => {
    if (Object.keys(shiftData).length !== 0) {
      updateShift(shiftData["shift"], shiftData);
      setshowShiftModal(false);
    }
  }, [shiftData]);

  const { addDocument: addDamagedProduct } = useFirestore("damagedProducts");

  useEffect(() => {
    if (Object.keys(damagedProductData).length !== 0) {
      addDamagedProduct(damagedProductData);
      setdamagedProductModal(false);
    }
  }, [damagedProductData]);

  return (
    <ProductWrapper>
      {/* Table */}
      <ProductForm
        setFormData={setFormData}
        setshowProductModal={setshowProductModal}
      />
    </ProductWrapper>
  );
}
