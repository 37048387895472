import styled from "styled-components";

export const ReportsWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 3rem;
`;
export const ProductWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReportDetails = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 0.8rem;
  .table_heading {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  }

  .table_content {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
  }

  .divider {
    color: #dcdbdb;
    border-width: 1px;
    border-color: aliceblue;
  }
`;

export const ReportsFormContainer = styled.div`
  background: ${({ bg }) => bg || "#000"};
  color: ${({ color }) => color || "#fff"};
  padding: 2rem;
  border-radius: 0.8rem;
  h3 {
    font-size: 2rem;
  }
  form {
    label {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      :not(:last-child) {
        margin-bottom: 1rem;
      }

      p {
        width: 10rem;
      }

      input,
      select {
        border: none;
        margin: 1rem;
        padding: 1rem 2rem;
        flex-grow: 4;
        border-radius: 0.4rem;
        :focus {
          outline: none;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    .submit {
      display: block;
      padding: 1.5rem 2rem;
      background: #f9a826;
      border: none;
      font-size: 1.8rem;
      border-radius: 0.4rem;
      margin: 0 auto;
      cursor: pointer;
    }
    .msgSpan {
      text-align: center;
      font-size: 2rem;
      margin: 1rem auto 0 auto;
      display: block;
    }
  }
`;

export const ProductFormContainer = styled.div`
  width: 100%;
  background: ${({ bg }) => bg || "#000"};
  color: ${({ color }) => color || "#fff"};
  padding: 2rem;
  border-radius: 0.8rem;
  h3 {
    font-size: 2rem;
  }
  form {
    h3 {
      padding-bottom: 10px;
    }
    label {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      :not(:last-child) {
        margin-bottom: 1rem;
      }

      p {
        width: 10rem;
      }

      input,
      select {
        border: none;
        margin: 1rem;
        padding: 1rem 2rem;
        flex-grow: 4;
        border-radius: 0.4rem;
        :focus {
          outline: none;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        p {
          width: 100%;
        }
        input,
        select {
          width: 100%;
          margin: 0;
        }
      }
    }
    .submit {
      display: block;
      padding: 1.5rem 2rem;
      background: #f9a826;
      border: none;
      font-size: 1.8rem;
      border-radius: 0.4rem;
      margin: 0 auto;
      cursor: pointer;
    }
    .msgSpan {
      text-align: center;
      font-size: 2rem;
      margin: 1rem auto 0 auto;
      display: block;
    }
  }
`;
