import { DashboardContent } from "../../styles/Dashboard.styled";
import { ReportHeadingWrapper } from "../../styles/Reports.styled";
import { CrainReportTableWrapper } from "./CrainTableWrapper";
import { TareReportTableWrapper } from "./TareTableWrapper";
import { useEffect, useState } from "react";
import { db_firestore } from "../../Hooks/config";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
  doc,
  updateDoc, // Import updateDoc
} from "firebase/firestore";
import { Button } from "../../styles/Common.styled";

function formatUnixTime(unixTime) {
  const date = new Date(unixTime * 1000);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
}

export default function TareAndCrainUpdates() {
  let [ReportData, setReportData] = useState([]);
  let [isTare, setIsTare] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [coilWeight, setCoilWeight] = useState({}); // State to track coil weights

  useEffect(() => {
    setDataLoaded(false);
    const ref = collection(
      db_firestore,
      isTare ? "tareUpdates" : "coilChangeUpdate"
    );
    const q = query(ref, orderBy("unix_time", "desc"), limit(50));
    onSnapshot(q, (snapShot) => {
      let items = [];
      snapShot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
        if (!isTare) {
          setCoilWeight((prev) => ({ ...prev, [doc.id]: doc.data().weight }));
        }
      });
      setReportData(items);
      setDataLoaded(true);
    });
  }, [isTare]);

  const handleUpdate = async (element, id) => {
    const docRef = doc(db_firestore, "coilChangeUpdate", id);
    const newWeight = coilWeight[id];
    console.log(id, newWeight);
    if (newWeight) {
      try {
        element.target.innerText = "wait...";
        await updateDoc(docRef, { weight: parseFloat(newWeight) });
        element.target.innerText = "Updated";
      } catch (error) {
        element.target.innerText = "error!";
      }
    } else {
      element.target.innerText = "Invalid!";
    }

    setTimeout(() => {
      element.target.innerText = "Update";
    }, 1500);
  };

  const handleWeightChange = (id, value) => {
    setCoilWeight((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <DashboardContent>
      <ReportHeadingWrapper>
        <p>Tare and Coil Changing Updates</p>
        <div className="right">
          <Button
            bg={!isTare ? "#E65192" : "black"}
            onClick={() => setIsTare(true)}
          >
            Tare Updates
          </Button>
          <Button
            bg={isTare ? "#E65192" : "black"}
            onClick={() => setIsTare(false)}
          >
            Coil Changing Updates
          </Button>
        </div>
      </ReportHeadingWrapper>
      {isTare
        ? dataLoaded && (
            <TareReportTableWrapper>
              <div className="table_heading">
                <p>ID</p>
                <p>Machine Number</p>
                <p>Tared On</p>
              </div>
              {ReportData.map((item, index) => (
                <span key={item.id}>
                  <hr className="divider" />
                  <div className="table_content">
                    <p>{index + 1}</p>
                    <p>{item?.machine_no}</p>
                    <p>{formatUnixTime(item?.unix_time)}</p>
                  </div>
                </span>
              ))}
            </TareReportTableWrapper>
          )
        : dataLoaded && (
            <CrainReportTableWrapper>
              <div className="table_heading">
                <p>ID</p>
                <p>Machine Number</p>
                <p>Changed On</p>
                <p>Coil Weight</p>
              </div>
              {ReportData.map((item, index) => (
                <span key={item.id}>
                  <hr className="divider" />
                  <div className="table_content">
                    <p>{index + 1}</p>
                    <p>{item?.machine_no}</p>
                    <p>{formatUnixTime(item?.unix_time)}</p>
                    <p>
                      <input
                        className="coil-update-input"
                        type="text"
                        placeholder="Enter coil weight"
                        value={coilWeight[item.id] || ""}
                        onChange={(e) =>
                          handleWeightChange(item.id, e.target.value)
                        }
                      />
                      <button
                        onClick={(e) => handleUpdate(e, item.id)}
                        className="coil-update-button"
                      >
                        Update
                      </button>
                    </p>
                  </div>
                </span>
              ))}
            </CrainReportTableWrapper>
          )}
    </DashboardContent>
  );
}
