import styled from "styled-components";

export const ReportHeadingWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  p {
    font-size: 1.8rem;
  }

  .right {
    display: flex;
    gap: 2rem;
    align-items: center;

    button:hover {
      background-color: #2c2c2c;
    }

    input {
      padding: 1rem 2rem;
      border: 1px solid #ddd;
      border-radius: 0.4rem;
      :focus {
        outline: none;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .right {
      flex-direction: column;
    }
  }
`;
export const ReportTableWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 0.8rem;
  .table_heading {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .table_content {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    text-align: center;
  }

  .divider {
    color: #dcdbdb;
    border-width: 1px;
    border-color: aliceblue;
  }
`;
