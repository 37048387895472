import styled from "styled-components";

export const CrainReportTableWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 0.8rem;
  .table_heading {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .coil-update-input {
    width: 100px;
    height: 28px;
    padding: 0px 5px;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #ccc;
    font-size: 12px;
    outline: none;
  }

  .coil-update-button {
    background: #f9a826;
    height: 28px;
    padding: 0px 10px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #ccc;
    border-left: none;
    color: white;
    position: relative;
    right: 1px;
    cursor: pointer;    
  }

  .coil-update-button:hover {
    background: #dc8800;
  }

  .table_content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    text-align: center;
  }

  .divider {
    color: #dcdbdb;
    border-width: 1px;
    border-color: aliceblue;
  }
`;
